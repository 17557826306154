<template>
  <fieldset>
    <legend>Tasks</legend>
    <div v-for="(name, index) in tasks" class="tasks">
      <create-task :index="index" :name="name" :users="users"/>
    </div>
  </fieldset>
</template>

<script>
import CreateTask from "./CreateTask"

export default {
  name: "CreateTasks",
  components: {CreateTask},
  props: ['tasks', 'users']
}
</script>

