import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './sass/app.scss';
import 'bootstrap';

// Import Vue 3 and createApp
import { createApp } from 'vue';

// Import components
import CreateTasks from "./components/CreateTasks";
import EditTasks from "./components/EditTasks";
import SelectCustomer from "./components/SelectCustomer";
import TimestampCheck from "./components/TimestampCheck";
// Fresh row counts
document.querySelectorAll('[data-refresh]').forEach((refresh) => {
    refresh.addEventListener('change', () => {
        let rows = refresh.options[refresh.selectedIndex].value
        let route = refresh.getAttribute('data-refresh');
        window.location.href = `${route}?rows=${rows}`
    });
});

// Create Vue 3 app
const app = createApp({
    components: {
        CreateTasks,
        EditTasks,
        SelectCustomer,
        TimestampCheck,
    }
});

// Mount the app
app.mount('#main');