<template>
    <div class="form-group">
        <div class="form-check">
            <input v-if="checked" :name="name" :value="timestamp" type="hidden">
            <input :id="`checked-at-${name}`" v-model="checked" class="form-check-input" type="checkbox">
            <label :for="`checked-at-${name}`" class="form-check-label">{{ label }}</label>
        </div>
    </div>
</template>

<script>
export default {
name: "TimestampCheck",
    props: {
        name: {
            type: String,
            required: true,
        },
        timestamp: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            checked: !!this.timestamp
        }
    },
    watch: {
        checked(checked) {
            this.timestamp = checked ? new Date().toISOString() : null
        }
    }
}
</script>

<style scoped>

</style>