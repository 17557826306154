<template>
  <fieldset>
    <div class="form-check">
      <input :id="`task${index}`" class="form-check-input" type="checkbox" v-model="enabled" :data-test="`${name}Task`">
      <label :for="`task${index}`" class="form-check-label">{{ name }}</label>
    </div>
    <div class="form-group" v-if="enabled">
      <label :for="`project_tasks_${index}_note`">Note</label>
      <QuillEditor v-model:content="note" :toolbar="toolbar" />
      <input type="hidden" :name="`project[tasks][${index}][name]`" :value="name">
      <input type="hidden" :id="`project_tasks_${index}_note`" :name="`project[tasks][${index}][note]`" v-model="note">
    </div>
    <div class="form-group" v-if="enabled">
      <legend class="col-form-label required">{{ name }} Assignees</legend>
      <div id="project_tasks_assignees">
        <div class="form-check" v-for="(user, taskIndex) in users" :key="user.id">
          <input type="checkbox" :id="`project_tasks_${taskIndex}_assignees`" :name="`project[tasks][${index}][assignees][]`" :value="user.id" class="form-check-input">
          <label :for="`project_tasks_${taskIndex}_assignees`" class="form-check-label">{{ user.name }}</label>
        </div>
      </div>
    </div>
  </fieldset>
</template>
<script setup>
import { ref } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

const props = defineProps({
  index: Number,
  name: String,
  users: Array,
})

const enabled = ref(false)
const note = ref('')

const toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'color': [] }, { 'background': [] }],
  [{ 'align': [] }],
  ['clean']
]
</script>
