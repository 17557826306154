<template>
  <fieldset>
    <div class="form-check">
      <input :id="`enabled${index}`" class="form-check-input" type="checkbox" v-model="enabled">
      <label :for="`enabled${index}`" class="form-check-label">{{ task.name }}</label>
    </div>
    <div v-if="enabled">
      <input :id="`project_tasks_${index}_id`" :name="`project[tasks][${index}][id]`" :value="task.id" type="hidden">
      <input :id="`project_tasks_${index}_name`" :name="`project[tasks][${index}][name]`" :value="task.name" type="hidden">
      <div class="form-group">
        <label :for="`project_tasks_${index}_note`">Note</label>
        <QuillEditor v-model:content="note" :toolbar="toolbar" />
        <input type="hidden" :id="`project_tasks_${index}_note`" :name="`project[tasks][${index}][note]`" v-model="note">
      </div>
      <div class="form-check">
        <input :id="`completed${index}`" v-model="completed" class="form-check-input" type="checkbox">
        <label :for="`completed${index}`" class="form-check-label">{{ task.name }} Complete</label>
        <input v-if="completed" :id="`project_tasks_${index}_completedAt`" :name="`project[tasks][${index}][completedAt]`" :value="task.completedAt" type="hidden">
      </div>
      <fieldset class="form-group">
        <legend class="col-form-label required">{{ task.name }} Assignees</legend>
        <div id="project_tasks_assignees">
          <div class="form-check" v-for="(user, taskIndex) in users" :key="user.id">
            <input type="checkbox" :id="`${task.name}_assignees_${taskIndex}`" :name="`project[tasks][${index}][assignees][]`" :value="user.id" class="form-check-input" v-model="task.assignees">
            <label :for="`${task.name}_assignees_${taskIndex}`" class="form-check-label">{{ user.name }}</label>
          </div>
        </div>
      </fieldset>
    </div>
  </fieldset>
</template>
<script setup>
import { ref, watch, onBeforeMount } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

const props = defineProps({
    index: {},
    task: {},
    users: Array,
})

const completed = ref(false)
const enabled = ref(true)
const note = ref('')

const toolbar = [
  ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'color': []}, {'background': []}],
        [{'align': []}],
  ['clean']
]

onBeforeMount(() => {
  completed.value = props.task.completedAt != null
  note.value = props.task.note
})

watch(completed, (newCompleted) => {
  props.task.completedAt = newCompleted ? new Date().toISOString() : null
})
</script>
