<template>
  <fieldset>
    <legend>Tasks</legend>
    <div v-for="(option, index) in options" class="tasks">
      <create-task :index="index" :name="option" :users="users" v-if="!selected.includes(option)"/>
      <edit-task :index="index" :task="task(option)" :users="users" v-if="selected.includes(option)"/>
    </div>
  </fieldset>
</template>

<script>
import EditTask from "./EditTask"
import CreateTask from "./CreateTask";

export default {
  name: "EditTasks",
  components: {CreateTask, EditTask},
  props: {
    options: Array,
    selected: Array,
    tasks: Array,
    users: Array,
  },
  methods: {
    task: function (option) {
      return this.tasks.find(task => task.name === option)
    }
  }
}
</script>
