<template>
  <div class="form-group">
    <label for="project_customer" class="required">Customer</label>
    <vue-select label="name" :options="options" v-model="selected" inputId="project_customer">
      <template #selected-option="{ id, name }">
        <div style="display: flex; align-items: baseline;">
          {{ name }}
        </div>
      </template>
    </vue-select>
    <input type="hidden" id="project_customer" name="project[customer]" class="form-control" :value="selected.id">
  </div>
</template>

<script setup>
import { ref, onBeforeMount, defineComponent } from 'vue'
import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const defaultCustomer = {
  id: 1,
  name: 'Cash Sale'
}

defineComponent({
  name: "SelectCustomer",
  props: {
    customer: {
      type: Object,
      default: () => defaultCustomer
    },
    options: Array,
  },
  components: {
    VueSelect
  },
  setup(props) {
    const selected = ref({})
    onBeforeMount(() => {
      selected.value = props.customer
    })
    return {
      selected
    }
  }
})
</script>
